@import "../Components/assets/sass/main";

.stepperWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1% 0px;
  position: relative;
  max-width: 1600px;
  margin: auto;
  @media (max-width: $tablet) {
    display: none;
  }

  .stepSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 9%;
    .step {
      display: flex;
      align-items: center;

      width: 100%;

      .stepIcon {
      }
    }
    .stepLine {
      width: 40%;
      height: 1px;
      background-color: #9fa4a5;
    }
    .stepTitle {
      width: 100%;
      text-align: center;
      font-size: 11px;
      margin: 3px 0px;
      color: var(--Neutral-Gray-60, #6f6f6f);

      font-family: "Be Vietnam Pro";

      font-style: normal;
      // font-weight: 400;
    }
  }
}

.circularProgress {
  display: none;
  @media (max-width: $tablet) {
    display: block;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    // position: absolute;
    left: 10px;
    top: 20px;
  }
}
