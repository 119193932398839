@import "../../Components/assets/sass/main";
.wrapper {
  display: flex;
  // background-color: #f7f7f8;
  flex-direction: column;
 
  // padding-bottom: 50px;

  @media (max-width: $tablet) {
    // padding: 30px 10px 50px 10px;
    flex-direction: column;
  }
  .wrapperTop {
    height: max-content;
    background-color: white;

    padding: 0px;
    @media (max-width: $tablet) {
      flex-direction: column;

      border-right: none;
    }
    .wrapperTopContent {
      // width: 100%;
      
      background-color: #4853a2;
      color: white;
      display: flex;
      height: max-content;
      padding: 4% 5%;
      @media (max-width: $tablet) {
        flex-direction: column;
        padding: 4% 10px;
      }
    }
  }

  .bottomContentSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .sublinksBottom {
      // color: white !important;
      text-decoration: none;
      font-size: 13px;
      padding: 7px 10px;
    }
    .copyRightsection {
      margin-top: 15px;
      .copyRightText {
        // color: white;
        font-size: 13px;
      }
    }
  }
}

.logoSection {
  width: 35%;
  display: flex;

  flex-direction: column;
  @media (max-width: $tablet) {
    width: 100%;
    // padding: 10px;
    height: max-content;
    border-bottom: none;
    margin-bottom: 20px;
  }
  .companylogo {
    // width: 120px;
    margin-bottom: 15px;
  }
}

.siteMaping {
  padding: 0px 30px;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: $tablet) {
    padding: 0;
    width: 100%;
  }
}
.siteMapingAlign {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: $tablet) {
    justify-content: space-between;
  }
}
.siteMapingLinks {
  padding-bottom: 0px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.privacyPolicyLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.sublinks {
  color: #dee2e6;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.606px;
  text-decoration: none;
  padding: 2px 0px;
  @media (max-width: $tablet) {
    // flex-direction: column;
  }
}
.sublinksnot {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  padding: 7px 0;
}
.sublinksnotAddress {
  width: 60%;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: $tablet) {
    width: 80%;
  }
}
.Title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.606px;
}
.linksandsublinks {
  display: flex;
  flex-direction: column;
  @media (max-width: $tablet) {
    width: 30%;
  }
}
.linksandsublinksLeft {
  display: flex;
  flex-direction: column;
}

.imageSection {
  padding: 0px 0px;
  display: flex;
  gap: 5px;

  flex-direction: column;
  @media (max-width: $tablet) {
    margin: 20px auto 10px;
    display: flex;
    justify-content: center;
  }
  .emailUs {
    color: #4c4c4c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    letter-spacing: -0.096px;
  }
  .emailText {
    color: #6b7280;
    font-size: 12px;
    font-style: normal;
    margin: 0;
    font-weight: 500;
    letter-spacing: -0.084px;
  }

  .icons {
    padding: 0 0px;
    // width: 40px;
  }
  .followusicons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
}

.cntf {
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-bottom: 2vh;
  max-width: 100%;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}
.beforeLink {
  // color: white;
}
.alink {
  text-decoration: underline;
  color: #4754a0;
  font-size: 14px;
  width: max-content;
}
.uLink {
  @media (max-width: $tablet) {
    padding: 0;
  }
}
.FirstockTitle {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.3rem;
  // color: white;
}
.topTitleBelowContent {
  font-size: 16px;
  font-weight: 500;
  // color: white;
}
.copyRightPara {
  color: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.565px;
}
.footerHeader {
  color: #4754a0;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  margin: 5px 0px;
}
.bottomLinks {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-left: 0px;
  width: 100%;
  margin: 5px 0px;

  // flex-wrap: wrap;
  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 0;
  }
}
.bottomDiv {
  display: flex;
  margin: auto;

  width: 90%;
  justify-content: center;
}
.bottomLinks2 {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  padding-left: 0px;
  @media (max-width: $tablet) {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
}

.linksandsublinkstoalignmobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // margin-bottom: 20px;
}

.siteMapingAlignMobile {
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.bottomExtraContent {
  margin-top: 20px;
  padding: 0% 5%;
  margin-bottom: 50px;
  @media (max-width: $tablet) {
    // width: 100%;
    padding: 0% 10px;
  }
}
.contentsectionnew {
}
.ectrabg {
  height: 40px;
  // width: 100%;
  background: #006779;
  @media (max-width: $tablet) {
    height: 60px;
    // width: 100%;
    background: #006779;
  }
}
