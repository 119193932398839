@import "../../Components/assets/sass/main";

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}
.wrapper {
  //padding-top: 40px;
  padding: 5% 0;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  // margin-bottom: 20px;
  @media (max-width: $tablet) {
    padding: 10px 10px 0 10px;
    height: max-content;
    flex-direction: column;
    width: 90%;
  }
  @media (min-width: 2000px) {
    height: 60vh;
  }
  .titleSection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // flex-direction: column-reverse;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
    .leftcontainer {
      width: 45%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      @media (max-width: $tablet) {
        width: 100%;
      }
      .title {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;
        width: 100%;
        display: block;

        @media (max-width: $tablet) {
          font-size: 25px;
          margin: 0 0 10px 0;
          display: none;
          // text-align: left;
        }
      }
      .leftcontainerText {
        color: #262626;
        // font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.049px; /* 115.246% */
        margin: 8px 0;
        letter-spacing: 0.576px;
        @media (max-width: $tablet) {
          margin: 3px 0;
          font-size: 14px;
          width: 100%;
        }
      }
    }
    .rightcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      width: 45%;
      @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0px 0 0px 0px;
        width: 100%;
      }
      .titleMobile {
        display: none;
        @media (max-width: $tablet) {
          display: flex;
          color: #262626;
          font-family: "Be Vietnam Pro";
          font-size: 25px;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          font-size: 20px;
          margin: 0 0 10px 0;
        }
      }
      .image {
        width: 80%;
        @media (max-width: $tablet) {
          width: 90%;
        }
        @media (min-width: 2000px) {
          width: 100%;
        }
      }
    }
  }
  .buttonStyles {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    // width: 100vw;
    @media (max-width: $tablet) {
      flex-direction: column;
      align-items: center;
    }
    .buttonKYC {
      width: max-content;
      margin-top: 20px;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
  .startOverSection {
    display: flex;
    margin-top: 10px;

    .iconImage {
      width: 20px;
    }
    .paratext {
      font-size: 14px;
      color: $SubText;
      margin-left: 5px;
      .startoverbutton {
        color: #006779;
        margin-left: 5px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  .displayBack {
    display: flex;
    flex-direction: column-reverse;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
  }
}

.fetchWrapper {
  min-height: 80vh;
  //padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;

  @media (max-width: $tablet) {
    //padding: 0px 10px 0 10px;
  }
  .fetchHeaderText {
    font-size: 30px;
    color: $Header;
    @media (max-width: $tablet) {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  .fetchContainer {
    border: 1px solid #e3e3e3;
    border-radius: 5px;

    padding: 20px;
    @media (max-width: $tablet) {
      margin: auto;
      width: 85vw;
      padding: 10px;
    }

    .fetchName {
      display: flex;
      @media (max-width: $tablet) {
        flex-direction: column;
      }

      .fetchYourName {
        color: $SubText;
        font-size: 16px;
        margin: 10px 0;
        display: flex;
      }
      .fetchFatherName {
        color: $SubText;
        font-size: 16px;
        padding-left: 20vw;
        margin: 10px 0;
        display: flex;

        @media (max-width: $tablet) {
          padding-left: 0vw;
        }
      }
    }
    .fetchDobandgender {
      display: flex;
      @media (max-width: $tablet) {
        flex-direction: column;
      }

      .fetchDateofBirth {
        color: $SubText;
        font-size: 16px;
        margin: 10px 0;
        display: flex;
      }
      .fetchGender {
        color: $SubText;
        font-size: 16px;
        padding-left: 20vw;
        margin: 10px 0;
        display: flex;

        @media (max-width: $tablet) {
          padding-left: 0vw;
        }
      }
    }
  }
  .fetchEmail {
    color: $SubText;
    font-size: 16px;
    margin: 10px 0 20px 0;
    display: flex;
  }
  .fetchAddress {
    color: $SubText;
    font-size: 16px;
    margin: 10px 0;
    display: flex;
    @media (max-width: $tablet) {
      display: block;
    }
  }
  .fetchButtons {
    padding: 30px 0 10px 0;
    display: flex;
    gap: 20px;
    @media (max-width: $tablet) {
      // flex-direction: column;
      width: 100%;
      margin: auto;
    }
    .fetchContinue {
      width: 200px;
      @media (max-width: $tablet) {
        width: max-content;
        padding: 10px 20px;
      }
    }
    .fetchUpdate {
      color: $Success;

      border-color: black;
      color: black;
      // width: 200px;
      padding: 10px 40px;
      @media (max-width: $tablet) {
        margin-right: 0px;
        margin-bottom: 10px;
        padding: 10px 20px;
        width: max-content;
      }
    }
  }
  .fetchIconandText {
    display: flex;
  }
  .fetchAadhaar {
    display: flex;
    align-items: start;
  }
}

.approveButton {
  width: 200px;
  // margin-top: 20px;
  margin-right: 0px !important;
  @media (max-width: $tablet) {
    margin-top: 10px;
    // width: max-content;
  }
}
.buttonStack {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $tablet) {
    flex-direction: column;
    margin-top: 20px;
  }
}

.startOverSection {
  display: flex;
  // margin-top: 10px;
  margin-bottom: 20px;

  .iconImage {
    width: 20px;
  }
  .paratext {
    font-size: 14px;
    color: $SubText;
    margin-left: 5px;
    margin-top: 12px;
    .startoverbutton {
      color: #006779;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.bottomContentContainer {
  padding: 0 50px 10px 50px;
  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 0 10px 0px 10px;
  }
}
.bottomContentPara {
  font-size: 14px;
  color: $SubText;
}

.redoStyles {
  display: flex;
  margin: 20px 0px;
  justify-content: center;
  width: 100%;
  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.mainWrapper {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;
  padding-bottom: 30px;
  width: 90%;
  height: max-content;

  background-color: white;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
