@import "../../Components/assets/sass/main";

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}
.wrapper {
  // height: 70vh;
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: $tablet) {
    flex-direction: column;
    // justify-content: space-between;
    padding: 10px 0px;
    // height: 85vh;
  }
  @media (min-width: 2000px) {
    min-height: 60vh;
  }

  .LeftContainer {
    width: 45%;
    // background-color: red;
    @media (max-width: $tablet) {
      width: 100%;
    }
    .image {
      width: 80%;
      @media (max-width: $tablet) {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      }
      @media (min-width: 1500px) {
        width: 80%;
      }
    }
  }

  .RightContainer {
    display: flex;
    flex-direction: column;
    // align-items: center;
    width: 45%;
    padding: 0px 3%;
    @media (max-width: $tablet) {
      width: 90%;
      padding: 0px 0%;
    }
    .headerSection {
      // text-align: center;
      @media (max-width: $tablet) {
        text-align: left;
        margin-top: 10px;
      }
      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;
        width: 100%;

        @media (max-width: $tablet) {
          font-size: 20px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .subText {
        // width: 400px;
        line-height: 30px;
        margin: 0;
        color: $SubText;
        font-size: 16px;
        @media (max-width: $tablet) {
          // width: 90vw;
          line-height: 20px;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
    .textfeildSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: left;
      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }
    .textfieldLabel {
      color: var(--Grey-15, #262626);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      margin-top: 10px;
      margin-bottom: 0px;
    }
    .textfeild {
      width: 100%;
      margin: 0px 0px 0px 0;
      @media (max-width: $tablet) {
        width: 100%;
        margin: 8px 0px 20px 0;
      }
    }
    .smallText {
      font-weight: 400;
      font-size: 12px;
      color: $SubText;
      margin: 5px 0;
    }
    .buttonSection {
      display: flex;
      // justify-content: center;
      @media (max-width: $tablet) {
      }
    }
    .button {
      width: 100%;
      margin-top: 30px;
      //  box-shadow: 0px 20px 60px rgba(114, 143, 129, 0.39);
      @media (max-width: $tablet) {
        // box-shadow: 0px 2px 10px rgba(114, 143, 129, 0.39);
        width: 100%;
        margin-top: 30px;
      }
    }
    .datePicker {
      width: 100%;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
}

.mainWrapper {
  border: 1px solid #d6d7dd;
  margin: 0px auto 40px;
  border-radius: 8px;
  padding-bottom: 30px;
  width: 90%;
  // max-width: 1500px;
  background-color: white;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileImageView {
  display: none;
  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageMobile {
    width: 80%;
    margin-top: 20px;
  }
}
