@import "../../Components/assets/sass/main";
.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;
  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: $tablet) {
    height: max-content;
    // margin-top: 60px;
    margin-bottom: 30px;
  }
  // @media (min-width: 2000px) {
  //   height: 70vh;
  // }
  .headerSection {
    width: 80%;
    margin-bottom: 20px;
    @media (max-width: $tablet) {
      width: 100%;
      margin-bottom: 0px;
    }
    .signup {
      color: #262626;
      font-family: "Be Vietnam Pro";
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 10px 0;
      width: 100%;
      text-align: left;

      @media (max-width: $tablet) {
        font-size: 20px;
        margin: 0 0 20px 0;
        line-height: 30px;
        // text-align: left;
      }
    }
    .subText {
      color: #475467;

      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 5px 0px;
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    @media (max-width: $tablet) {
      padding: 0 10px 0px 10px;
    }
    .contentContaineWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      @media (max-width: $tablet) {
        flex-direction: column;
        margin-top: 10px;
      }

      .contentContainer {
        width: 75%;
        @media (max-width: $tablet) {
          width: 100%;
        }

        .checkBoxDiv {
          // height: 40px;
          margin-bottom: 10px;
          @media (min-width: 2000px) {
            margin: 20px 0;
          }
          .label {
            color: #475467;
            text-align: justify;
            font-family: "Be Vietnam Pro";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            display: flex;

            @media (max-width: $tablet) {
              font-size: 12px;
              text-align: left;
              display: flex;
              // flex-wrap: wrap;
            }
          }
        }
      }
    }
  }

  .imageContainerMobile {
    width: 25%;
    display: none;
    @media (max-width: $tablet) {
      display: block;
      width: 80%;
      margin: auto;
    }

    .image {
      width: 100%;
      @media (max-width: $tablet) {
        width: 100%;
        margin-top: 10px;
      }
      @media (min-width: 2000px) {
        width: 70%;
        // margin-top: 10px;
      }
    }
  }
  .imageContainerDesktop {
    width: 25%;
    display: block;
    @media (max-width: $tablet) {
      display: none;
      width: 80%;
    }

    .image {
      width: 100%;
      @media (max-width: $tablet) {
        width: 100%;
        margin-top: 10px;
      }
      @media (min-width: 2000px) {
        width: 70%;
        // margin-top: 10px;
      }
    }
  }
  .approveButton {
    width: 180px;

    @media (max-width: $tablet) {
      width: 140px;
      padding: 10px 20px;
    }
  }
  .buttonStack {
    // margin-top: 10px;
    display: flex;
    margin-top: 2%;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
      // flex-direction: column;
      margin-top: 15%;
    }
  }
  .backButton {
    width: 180px;
    padding: 10px 40px;
    color: #262626;
    font-style: normal;
    font-weight: 500;
    @media (max-width: $tablet) {
      padding: 10px 20px;
      width: 140px;
    }
  }
}

.aTag {
  color: #4754a0;
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 600;
}
.pdfMobilePreview {
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.pdfDesktopPreview {
  display: block;
  height: 85%;
  @media (max-width: $tablet) {
    display: none;
  }
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.mobileLine {
  display: flex;
  @media (max-width: $tablet) {
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
}
