@import "../../Components//assets/sass/main";

.pdfMobilePreview {
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}

.pdfDesktopPreview {
  display: block;
  height: 70vh;
  @media (max-width: $tablet) {
    display: none;
  }
}
