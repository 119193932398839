@import "../../Components//assets/sass/main";

.selectText {
  color: #000;

  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.formatText {
  color: rgba(0, 0, 0, 0.4);
  margin-top: 0px;
  font-family: "Be Vietnam Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
