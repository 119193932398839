@import "../../Components/assets/sass/main";
.header {
  color: #262626;
  font-family: "Be Vietnam Pro";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0px 0;

  @media (max-width: $tablet) {
    font-size: 25px;
    margin: 20px 0 5px 0;
    // text-align: left;
  }
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $tablet) {
    width: 100%;
  }
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0px;
}
