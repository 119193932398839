$antiflashWhite: #ecf0f6;
$Header: #344054;
$SubText: #475467;
$Success:rgba(18, 183, 106, 1);
$BlueText:rgba(31, 94, 250, 1);
// $amethyst: #bf73c6;
// $burntSienna: #df7b47;
// $thistle: #ded3f3;
// $periwinkle: #ccbfe9;
// $tropicalIndigo: #9a7ddd;
// $mint: #64cda4;
// $black: #0c0c0c;
// $grey: #7f7f7f;
// $lightGrey: #c1c1c1;
// $subtleGrey:#e4e3e3;
// $white: #ffffff;

$buttonGradient: linear-gradient(
  135deg,
  rgba(232, 108, 178, 1) 0%,
  rgba(189, 107, 215, 1) 100%
);

$boxshadow1:rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$boxshadow2:rgba(149, 157, 165, 0.2) 0px 8px 24px;
