@import "../../Components/assets/sass/main";
.wrapper {
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  border-radius: 10px;
  // box-shadow: 0px 0px 4px 0px grey;
  width: 75%;
  background-color: white;
  margin: 2% auto;
  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0px 30px 0px;
    height: max-content;
    width: 95%;
    box-shadow: none;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }

  .LeftContainer {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    position: relative;
    background: #4853a2;
    @media (max-width: $tablet) {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 20px;
    }
    .LeftContainerHeader {
      margin: 0px 0px 15px 0px;
      color: white;
      width: 80%;
      font-size: 20px;
      text-align: center;
      font-weight: 500;

      line-height: 25px;
      top: 20px;
      @media (max-width: $tablet) {
        // display: none;
      }
    }
    .LeftContainerSubText {
      margin: 0px 0px 0px 0px;

      top: 70px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: $tablet) {
        // display: none;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      // margin-top: 100px;
      //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
      @media (max-width: $tablet) {
        height: 80%;
        width: 70%;
        // display: none;
      }
    }
  }

  .RightContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 0px 3%;
    @media (max-width: $tablet) {
      width: 95%;
      padding: 0px 0%;
    }
    .headerSection {
      display: flex;
      // width: 100%;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      @media (max-width: $tablet) {
        padding: 0px 10px;
      }
      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 0px 0;

        @media (max-width: $tablet) {
          font-size: 25px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .subText {
        color: var(--Grey-30, #4c4c4d);
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
        @media (max-width: $tablet) {
          width: 100%;
          line-height: 20px;
          font-size: 15px;
          // text-align: left;
        }
      }
    }
    .textfeildSection {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
    .textfieldLabel {
      color: var(--Grey-15, #262626);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin: 5px 0px;
    }
    .textfeild {
      width: 100%;

      @media (max-width: $tablet) {
        width: 90%;
        margin: 0px 0px 0px 0px;
      }
    }
    .smallText {
      font-weight: 400;
      font-size: 12px;
      color: $SubText;
      margin: 5px 0;
    }
    .buttonSection {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      @media (max-width: $tablet) {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
    .button {
      width: 100%;
      margin-top: 0px;
      //  box-shadow: 0px 20px 60px rgba(114, 143, 129, 0.39);
      @media (max-width: $tablet) {
        // box-shadow: 0px 2px 10px rgba(114, 143, 129, 0.39);
        // margin-top: 20px;
        width: 100%;
      }
    }
    .buttonGoogle {
      width: 100%;
      margin-top: 20px;
      @media (max-width: $tablet) {
      }
    }

    .mobileImage {
      display: none;
      @media (max-width: $tablet) {
        display: block;
      }
      .imageMobileView {
        width: 100%;
        overflow: hidden;
        //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
        @media (max-width: $tablet) {
          margin-left: -20%;
          height: 35vh;
        }
      }
    }
  }
}

.mobileHeader {
  display: none;
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10px 10px 10px;
    text-align: center;
  }
  .LeftContainerHeadermobileHeader {
    margin: 0px 0px 8px 0px;
    color: #005454;
    font-size: 20px;
    font-weight: 500;
  }
  .LeftContainerSubTextmobileHeader {
    margin: 0px 0px 0px 0px;
    color: #005454;
    font-size: 14px;
    font-weight: 500;
  }
}
