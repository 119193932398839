@import "../../Components/assets/sass/main";

.mainWrapper {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;
  // padding-bottom: 30px;
  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // margin-top: 50px;
  height: max-content;
  display: flex;
  // align-items: center;
  justify-content: space-evenly;
  // width: 100%;
  // margin-bottom: 40px;
  padding: 0px 40px;
  @media (max-width: $tablet) {
    flex-direction: column;
    height: max-content;
    padding: 0 10px;
    width: unset;
    margin-bottom: 20px;
  }
  @media (min-width:1500px){
    min-height: 50vh;
  }

  .leftContainer {
    // text-align: center;
    width: 95%;
    @media (max-width: $tablet) {
      width: 100%;
    }

    .headerSection {
      margin-bottom: 30px;
      @media (max-width: $tablet) {
      }
      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;
        width: 100%;

        @media (max-width: $tablet) {
          font-size: 20px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
    }
    .textfield {
      width: 100%;
      margin-right: 10px;

      @media (max-width: $tablet) {
        width: 100%;
        margin-right: 0;
      }
    }
    .topContainer {
      display: flex;
      justify-content: space-between;
      margin: 0px 0px 20px 0px;
      width: 100%;
      @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0px 0px 20px 0px;
      }

      .subContainer {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        text-align: center;
        @media (max-width: $tablet) {
          width: 100%;
          margin-top: 20px;
        }
      }
      .subRightContainer {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 20px;
        @media (max-width: $tablet) {
          width: 100%;
          margin-top: 20px;
        }
      }
    }

    .startOverSection {
      display: flex;
      margin-top: 10px;

      .iconImage {
        width: 20px;
      }
      .paratext {
        font-size: 14px;
        color: $SubText;
        margin-left: 5px;
        .startoverbutton {
          color: #006779;
          margin-left: 5px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .approveButton {
      width: 200px;
      margin-top: 20px;
      padding: 10px 40px;
      @media (max-width: $tablet) {
        padding: 10px 40px;
        width: max-content;
      }
    }
    .buttonStack {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
        flex-direction: column;
        margin-top: 20px;
      }
    }
  }
  .rightContainer {
    width: 20%;
    @media (max-width: $tablet) {
      width: unset;
    }
  }
  .divider {
    padding: 10px;
    margin-right: 40px;
    @media (max-width: $tablet) {
      display: none;
    }
  }
}

.topContainerOccupation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin: auto;
  margin: 40px auto;
  text-align: center;

  @media (max-width: $tablet) {
    width: 100%;
  }
  .subRightContainerOccupation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.chipsstylecontainer {
  background: #f8f8f8;
  border-radius: 25px;
  padding: 20px 0px;
  width: 100%;
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 10px 0px;
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
