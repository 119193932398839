@import "../../Components/assets/sass/main";

.mainWrapper {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;

  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // height: 80vh;
  min-height: 60vh;
  margin: auto;
  width: 90%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $tablet) {
    // height: 90vh;
  }
  .headerSection {
    width: 100%;

    @media (max-width: $tablet) {
    }

    .signup {
      color: #262626;
      font-family: "Be Vietnam Pro";
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 20px 0;
      width: 100%;
      text-align: left;

      @media (max-width: $tablet) {
        font-size: 20px;
        margin: 0 0 10px 0;
        // text-align: left;
      }
    }
  }
  .checkBoxFeild {
    display: flex;
    position: relative;
    height: 50px;
    border: 1px solid #4754a0;
    padding: 0 20px;
    width: 264px;
    margin: 5px 0;
    border-radius: 10px;
    // background-color: #ECFDF3;
    @media (max-width: $tablet) {
      width: 304px;
    }
  }

  .startOverSection {
    display: flex;
    margin-top: 10px;

    .iconImage {
      width: 20px;
    }
    .paratext {
      font-size: 14px;
      color: $SubText;
      margin-left: 5px;
      .startoverbutton {
        color: #006779;
        margin-left: 5px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .approveButton {
    width: 180px;

    @media (max-width: $tablet) {
      width: 140px;
      padding: 10px 20px;
    }
  }
  .buttonStack {
    // margin-top: 10px;
    display: flex;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
      // flex-direction: column;
      margin-top: 15%;
    }
  }
  .backButton {
    width: 180px;
    padding: 10px 40px;
    color: #262626;
    font-style: normal;
    font-weight: 500;
    @media (max-width: $tablet) {
      padding: 10px 20px;
      width: 140px;
    }
  }
}

.defaultLabel {
  position: absolute;
  right: 40px;
  background: #4754a0;

  color: white;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
  @media (max-width: $tablet) {
    // margin-left: 261.5px;
  }
}
.tradingLabel {
  color: var(--Color-Grey-900, #111827);
  font-family: "Be Vietnam Pro";
  font-size: 14;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27.429px */
  letter-spacing: 0.229px;
}

.boxModalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: max-content;
  background: white;
  padding: 8px;
  border-radius: 24px;
  @media (max-width: 768px) {
    overflow-y: hidden;
    width: 95vw;
    height: 90vh;
  }
}

.riskdisclosuremodalwrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  // overflow-y: scroll;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 10px 30px 10px;
  }
}

.riskDisclosuresWrapper {
  width: 100%;
  height: max-content;
  background-color: white;
  padding: 20px;
  border-radius: 24px;

  @media (max-width: 768px) {
    width: 98%;
    height: max-content;
    background-color: white;
    padding: 20px 10px;
  }
}

.riskdisclosuretopsection {
  display: flex;
  align-items: center;
  gap: 10px;
}
.riskdisclosuresheader {
  width: 100%;
  color: var(--black-9001-a-1-a-1-a, #1a1a1a);
  text-align: center;

  font-family: "Be Vietnam Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 10px 0px;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.riskdisclosurecentersection {
  padding: 10px 0px 20px 0px;
  @media (max-width: 768px) {
    height: 70%;
    overflow-y: scroll;
  }
}
.riskdiclosureul {
  @media (max-width: 768px) {
    padding-left: 15px;
    margin: 0px;
  }
}
.riskdiclosureulli {
  font-size: 15px;
  margin-top: 20px;
  font-weight: 400;
  color: #4a4a4a;
  font-family: "Be Vietnam Pro";
  font-style: normal;
  font-weight: 400;
}
.riskdiclosureparadesc {
  color: #4a4a4a;
  font-family: "Be Vietnam Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: 200;

  line-height: 20px;
  margin-left: 6%;
  @media (max-width: 768px) {
    color: grey;

    font-size: 13px;
    line-height: 20px;
    margin-left: 0px;
  }
}

.riskdisclosureatag {
  color: rgb(10, 124, 231);
  text-decoration: none;
  padding: 0px 3px;
}
.riskdisclosurebuttonsection {
  padding: 5px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.riskdisclosurebutton {
  // background-color: #77c155;
  border: none;
  padding: 15px 40px;
  border-radius: 4px;
  color: white;
  width: max-content !important;
  // margin-top: 10px !important;
  // margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px !important;
  }
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}