@import "../../Components/assets/sass/main";
.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 40px;
  border-radius: 8px;
  width: 90%;
  background-color: white;
  padding: 30px 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 20px 0;
    position: relative;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  @media (max-width: $tablet) {
    height: max-content;
    margin-bottom: 30px;
  }

  .contentWrapper {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    width: 30%;
    @media (max-width: $tablet) {
      width: 90%;
      margin-top: 50px;
    }

    .contentTitle {
      color: #161e54;
      font-family: "Be Vietnam Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin: 20px 15px;
    }
    .subtext {
      color: var(--Primary-Subtitle-Color, #686868);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin: 10px 15px;
    }
    .subtextSummary {
      color: var(--Primary-Subtitle-Color, #686868);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin: 30px 15px 10px 15px;
    }
    .imageSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      background: #fafafa;
      margin: 0px 15px;
    }
    .totalText {
      color: #1a1a1a;
      font-family: "Be Vietnam Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;

      margin: 5px 0px;
    }
    .amount {
      color: #1a1a1a;
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      margin: 5px 0px;
    }
    .desc {
      color: #acacac;
      font-family: "Be Vietnam Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      margin: 0px;
    }
  }
}

.buttonStack {
  display: flex;
  margin-top: 20px;
  background: #4754a0;
  color: white;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  padding: 15px 0px;
  border-radius: 0px 0px 12px 12px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  cursor: pointer;
}
