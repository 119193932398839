@import "../../Components/assets/sass/main";

.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;

  width: 90%;
  background-color: white;
  padding: 1% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  align-items: center;
  // height: 110vh;
  width: 90%;
  margin: auto;
  padding: 2% 0px;
  @media (max-width: $tablet) {
    height: max-content;
    align-items: center;
    margin-bottom: 20px;
  }
  @media (min-width: 1600px) {
    height: 70vh;
    justify-content: center;
  }

  .Header {
    color: #262626;
    font-family: "Be Vietnam Pro";
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 20px 0;
    width: 100%;
    text-align: left;

    @media (max-width: $tablet) {
      font-size: 20px;
      margin: 0 0 10px 0;
      text-align: left;
    }
  }
  .subText {
    color: $SubText;
    text-align: center;
    font-size: 14px;
    @media (max-width: $tablet) {
      text-align: left;
      // width: 90%;
      // padding-left: 20px;
    }
  }
  form {
    width: 100%;
    @media (max-width: $tablet) {
      width: 95%;
    }
    .container {
      display: flex;
      justify-content: center;
      @media (max-width: $tablet) {
        flex-direction: column;
      }

      .feildSection {
        margin: 15px 0;
        .textfeild {
          width: 400px;
          margin: 0 15px;
          @media (max-width: $tablet) {
            width: 90%;
          }
          @media (min-width: 2000px) {
            width: 600px;
            margin: 20px 15px;
          }
        }
      }
    }
    .accountSection {
      display: flex;
      justify-content: center;
      align-items: center;

      .dropDownSection {
        width: 400px;
        margin-top: 20px;
        @media (max-width: $tablet) {
          width: 90%;
        }
      }
    }

    .startOverSection {
      display: flex;
      margin-top: 10px;

      .iconImage {
        width: 20px;
      }
      .paratext {
        font-size: 14px;
        color: $SubText;
        margin-left: 5px;
        .startoverbutton {
          color: #006779;
          margin-left: 5px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .approveButton {
      width: 180px;
      font-style: normal;
      font-weight: 500;
      padding: 10px 40px;
      @media (max-width: $tablet) {
        padding: 10px 20px;
        width: 140px;
      }
    }
    .backButton {
      width: 180px;

      padding: 10px 40px;
      color: #262626;
      font-style: normal;
      font-weight: 500;
      @media (max-width: $tablet) {
        padding: 10px 20px;
        width: 140px;
      }
    }
    .buttonStack {
      margin-top: 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;

      @media (max-width: $tablet) {
      }
    }
  }
  .buttonBackandContinueSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
    }

    .buttonBack {
      margin-top: 20px;
      margin-right: 30px;
      width: 100px;
      @media (max-width: $tablet) {
        margin-top: 10px;
        margin-right: 0px;
        margin-bottom: 20px;
        width: 80vw;
      }
    }
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 30px;
  // padding: 2% 0px;
  @media (max-width: $tablet) {
    height: max-content;
    align-items: center;
    margin-bottom: 20px;
  }
  @media (min-width: 1600px) {
    height: 70vh;
    align-items: center;
    margin-bottom: 20px;
  }
  .para {
    font-size: 14px;
    margin: 0px 0 10px 0px;
    font-family: "Be Vietnam Pro";
    color: $SubText;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.24px;
  }

  .Header {
    color: #262626;
    font-family: "Be Vietnam Pro";
    font-size: 25px;
    font-weight: 600;
    line-height: normal;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: center;

    @media (max-width: $tablet) {
      font-size: 20px;
      margin: 0 0 10px 10px;
      text-align: left;
    }
  }
  .subText {
    color: $SubText;
    text-align: center;
    font-size: 14px;
    margin-top: 0px;
    @media (max-width: $tablet) {
      text-align: center;
      width: 90%;
      padding-left: 20px;
    }
  }

  .mainConatiner {
    // width: 100%;
    width: 50%;
    display: flex;
    height: max-content;
    justify-content: center;
    // align-items: center;
    gap: 20px;

    border-radius: 16px;
    padding: 10px 0px;
    @media (max-width: $tablet) {
      flex-direction: column;
      padding: 10px 10px 20px 10px;
      width: 80%;
      margin-top: 10px;
    }
  }

  .subContainer {
    width: 50%;
    // border: 1px solid grey;
    border: 1.5px solid #d9d9d9;
    border-radius: 10px;
    cursor: pointer;
    display: flex;

    justify-content: space-between;

    // background: #ECFDF3;
    padding: 10px;
    @media (max-width: $tablet) {
      width: 90%;
    }
    @media (min-width: 2000px) {
      width: 40%;
    }

    .contentConatiner {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0px 10px;

      @media (max-width: $tablet) {
        flex-direction: column;
        position: relative;
      }

      .radioSection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        background-color: #f2f2f6;
        border-radius: 10px;

        width: 100%;
        @media (max-width: $tablet) {
          width: 100%;
          // justify-content: flex-end;
          // position: absolute;
          // right: 3%;
        }
      }
      .selectText {
        color: var(--Color-Grey-900, #111827);
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-left: 10px;
      }

      .subContentContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          color: #000;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "Be Vietnam Pro";
          font-size: 12.93px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-right: 10px;
          // margin-bottom: 6px;
        }

        .image {
          width: 50px;
          height: 20px;
        }
      }
    }
  }

  .startOverSection {
    display: flex;
    margin-top: 10px;

    .iconImage {
      width: 20px;
    }
    .paratext {
      font-size: 14px;
      color: $SubText;
      margin-left: 5px;
      .startoverbutton {
        color: #006779;
        margin-left: 5px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .approveButton {
    width: 180px;
    font-style: normal;
    font-weight: 500;
    padding: 10px 40px;
    @media (max-width: $tablet) {
      padding: 10px 20px;
      width: 140px;
    }
  }
  .backButton {
    width: 180px;

    padding: 10px 40px;
    color: #262626;
    font-style: normal;
    font-weight: 500;
    @media (max-width: $tablet) {
      padding: 10px 20px;
      width: 140px;
    }
  }
  .buttonStack {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: $tablet) {
    }
  }
}

.upiLink {
  text-decoration: none;
  margin-bottom: 20px;
}

.mobileUpiLink {
  display: none;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.desktopIframe {
  display: block;
  height: 100vh;
  @media (max-width: $mobile) {
    display: none;
  }
}
.buttonBackStyles {
  display: block;
  width: 200px;
  margin-top: 30px;
  @media (max-width: $tablet) {
    display: none;
  }
}
.circularprocess {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.displayFetchedData {
  width: 60vw;
  @media (max-width: $tablet) {
    width: 90vw;
  }
}

.displayFetchedButton {
  display: flex;
  margin-top: 40px;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}
.displayFetchedButtonRedo {
  width: 150px;

  @media (max-width: $tablet) {
    width: 90vw;
  }
}

.displayFetchedButtonContinue {
  width: 300px;
  margin-left: 30px;
  @media (max-width: $tablet) {
    width: 90vw;
    margin-left: 0px;
    margin-top: 20px;
  }
}

.backdropStyles {
  height: 100vh;
}

.startOverSection {
  display: flex;
  margin-top: 10px;

  .iconImage {
    width: 20px;
  }
  .paratext {
    font-size: 14px;
    color: $SubText;
    margin-left: 5px;
    .startoverbutton {
      color: #006779;
      font-weight: 600;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.imagestyles {
  width: 100%;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.textcontent {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  @media (max-width: $tablet) {
    text-align: center;
  }
}

.aligndiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: start;
  }
}

.textfield {
  width: 100%;
  margin-right: 10px;

  @media (max-width: $tablet) {
    width: 100%;
    margin-right: 0;
  }
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 10px 0px;
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.iosPaymentUI {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: #e2e6e9;
  padding: 10px;
  margin: 20px 0px;

  .selectText {
    margin: 5px 0px;
    font-size: 14px;
    font-weight: 500;
  }
  .amountText {
    margin: 0px 0px;
    font-size: 18px;
    font-weight: 400;
  }

  .paymentsOptionsSction {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .paymentOptions {
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: white;
      padding: 3px 10px;
      border-radius: 5px;
    }
  }
}
