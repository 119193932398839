@import "../../Components//assets/sass/main";

.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;

  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // height: 85vh;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: $tablet) {
    justify-content: flex-start;
    width: 90%;
  }
  @media (min-width: 2000px) {
    min-height: 50vh;
  }

  .headerSection {
    width: 100%;
    @media (max-width: $tablet) {
    }
    .signup {
      color: #262626;
      font-family: "Be Vietnam Pro";
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 20px 0;
      width: 100%;
      text-align: left;

      @media (max-width: $tablet) {
        font-size: 20px;
        margin: 0px 0 30px 0;
        // text-align: left;
      }
    }
  }

  .contentWrapper {
    width: 80%;
    margin: auto;
    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  .uploadSection {
    display: flex;
    justify-content: space-between;
    // padding: 0 30px 0 10px;
    align-items: center;
    background: #f2f2f6;
    border-radius: 10px;
    width: 100%;
    margin: 0px 0 0px 0;
    height: 55px;
    @media (max-width: $tablet) {
      width: 100%;
      padding: 0 5px;
    }

    .uploadSectionSelectDropDown {
      width: 300px;
      @media (max-width: $tablet) {
        width: 160px;
      }
    }
  }
  .uploadSignatureSection {
    display: flex;
    justify-content: space-between;
    // padding: 0 30px 0 10px;
    padding: 0px 20px;
    align-items: center;
    background: #f2f2f6;
    border-radius: 10px;
    // width: 100%;
    margin: 0px 0 0px 0;
    height: 55px;
    @media (max-width: $tablet) {
      width: 98%;
      padding: 0 5px;
    }
  }
  .subText {
    color: var(--Grey-40, #656567);
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: $tablet) {
      font-size: 12px;
    }
  }

  .startOverSection {
    display: flex;
    margin-top: 10px;

    .iconImage {
      width: 20px;
    }
    .paratext {
      font-size: 14px;
      color: $SubText;
      margin-left: 5px;
      .startoverbutton {
        color: #006779;
        margin-left: 5px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  .approveButton {
    width: 180px;

    @media (max-width: $tablet) {
      width: 140px;
      padding: 10px 20px;
    }
  }
  .buttonStack {
    // margin-top: 10px;
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
    }
  }
  .backButton {
    width: 180px;
    padding: 10px 40px;
    color: #262626;
    font-style: normal;
    font-weight: 500;
    @media (max-width: $tablet) {
      padding: 10px 20px;
      width: 140px;
    }
  }
  .messageSection {
    width: 50%;
    font-family: Be Vietnam Pro;
    margin: 30px auto;
    text-align: center;
    color: #1a1a1a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.576px;
    @media (max-width: $tablet) {
      width: 100%;
    }
  }
  .infoSection {
    color: var(--Grey-15, #262626);
    font-family: "Be Vietnam Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 5px;
  }
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 5px;
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
