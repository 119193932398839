@import "../../Components/assets/sass/main";

.main {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;

  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.mainWrapper {
  // height: 85vh;
  margin-bottom: 20px;
  @media (max-width: $tablet) {
    height: 100vh;
  }

  .wrapper {
    // height: 60vh;
    height: max-content;

    display: flex;

    justify-content: space-between;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
    @media (min-width: 2000px) {
      height: 50vh;
    }

    .Container {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      @media (max-width: $tablet) {
        flex-direction: column;
        width: 90%;
      }
      .imageSection {
        @media (max-width: $tablet) {
          margin-top: 10px;
        }
      }
    }
    .rightContainer {
      width: 55%;

      @media (max-width: $tablet) {
        width: 90%;
      }
    }

    .subText {
      color: $SubText;
      text-align: left;
      font-size: 14px;
      margin: 5px 0 5px 0;
      width: 100%;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttonSection {
    display: flex;

    width: 100%;
    gap: 20px;
    margin-top: 30px;

    @media (max-width: $tablet) {
      width: 90%;
      justify-content: center;
      align-items: center;
    }
    .buttonStyles {
      margin-right: 10px;
      width: 180px;
      @media (max-width: $tablet) {
        width: 140px;
      }
    }
  }

  .takePhoto {
    width: 180px;

    color: white;
    @media (max-width: $tablet) {
    }
  }
  .continueButton {
    width: 160px;
    @media (max-width: $tablet) {
      width: 140px;
    }
  }
}
.noFaceTextDiv {
  height: 15px;
  margin-bottom: 20px;
}
.noFaceText {
  font-size: 12px;
  color: rgba(255, 0, 0, 0.815);
}

.startOverSection {
  display: flex;
  margin-top: 10px;

  .iconImage {
    width: 20px;
  }
  .paratext {
    font-size: 14px;
    color: $SubText;
    margin-left: 5px;
    .startoverbutton {
      color: #006779;
      margin-left: 5px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.textdiv {
  text-align: left;
  .instruction {
    color: #262626;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.049px; /* 96.038% */
    letter-spacing: 0.576px;
  }
  .instructiontext {
    color: #262626;
    margin: 5px 0px;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Be Vietnam Pro";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.049px;
    letter-spacing: 0.576px;
  }
}
.backButton {
  width: 160px;
  padding: 10px 40px;
  color: #262626;
  font-style: normal;
  font-weight: 500;
  @media (max-width: $tablet) {
    padding: 10px 20px;
    width: 140px;
  }
}
.HeaderMobile {
  color: #262626;
  font-family: "Be Vietnam Pro";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 20px 0;
  width: 100%;
  text-align: left;
  display: none;
  @media (max-width: $tablet) {
    font-size: 20px;
    margin: 0 0 20px 0;
    display: block;
    // text-align: left;
  }
}
.Header {
  color: #262626;
  font-family: "Be Vietnam Pro";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 20px 0;
  width: 100%;
  text-align: left;
  display: block;
  @media (max-width: $tablet) {
    font-size: 25px;
    margin: 0 0 10px 0;
    display: none;
    // text-align: left;
  }
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
