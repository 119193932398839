@import "../../Components/assets/sass/main";

.wrapper {
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  border-radius: 10px;
  // box-shadow: 0px 0px 4px 0px grey;
  width: 75%;
  background-color: white;
  margin: 2% auto;
  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0px 30px 0px;
    height: max-content;
    width: 95%;
    box-shadow: none;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }

  .LeftContainer {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    position: relative;
    background: #4853a2;
    @media (max-width: $tablet) {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 20px;
    }

    .LeftContainerHeader {
      margin: 0px 0px 15px 0px;
      color: white;
      width: 80%;
      font-size: 20px;
      text-align: center;
      font-weight: 500;

      line-height: 25px;
      top: 20px;
      @media (max-width: $tablet) {
        // display: none;
      }
    }
    .LeftContainerSubText {
      margin: 0px 0px 0px 0px;

      top: 70px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: $tablet) {
        // display: none;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      // margin-top: 100px;
      //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
      @media (max-width: $tablet) {
        height: 80%;
        width: 70%;
        // display: none;
      }
    }
  }

  .RightContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 0px 3%;
    @media (max-width: $tablet) {
      width: 95%;
      padding: 0px 0%;
    }
    .headerSection {
      @media (max-width: $tablet) {
      }
      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 0px 0;

        @media (max-width: $tablet) {
          font-size: 25px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .subText {
        color: var(--Grey-30, #4c4c4d);
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
        @media (max-width: $tablet) {
          width: 100%;
          line-height: 20px;
          font-size: 15px;
          // text-align: left;
        }
      }
    }
    .change {
      font-size: 12px;
      color: #006779;
      cursor: pointer;
    }
    .textfield {
      width: 100%;
      margin-top: 30px;
      @media (max-width: $tablet) {
        width: 90%;
        margin: 20px 0px 0px 0px;
      }
    }
    .smallText {
      font-weight: 400;
      font-size: 12px;
      color: $SubText;
      margin: 5px 0;
    }
    .buttonSection {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      @media (max-width: $tablet) {
        display: flex;
        justify-content: center;
      }
    }
    .button {
      width: 100%;
      margin-top: 30px;
      //  box-shadow: 0px 20px 60px rgba(114, 143, 129, 0.39);
      @media (max-width: $tablet) {
        // box-shadow: 0px 2px 10px rgba(114, 143, 129, 0.39);
        margin-top: 20px;
      }
    }
    .resendSection {
      display: flex;
      margin-top: 00px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 14px;
      // height: 60px;
      @media (max-width: $tablet) {
        justify-content: center;
      }
    }
    .clickhere {
      margin: 0;
      margin-top: 2px;
      padding-right: 10px;
      padding-left: 0px;
      color: $SubText;
      font-size: 14px;
    }
    .mobileImage {
      display: none;
      @media (max-width: $tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .imageMobileView {
        width: 100%;
        overflow: hidden;
        //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
        @media (max-width: $tablet) {
          margin-left: -40%;
          height: 100%;
        }
      }
    }
  }
}

.inputBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 390px;
  // height: 64px;
  margin: 20px 0px 10px 0px;
  transition: 0.3s;
  @media (max-width: $tablet) {
    width: 100%;
    margin: auto;
  }
  @media (min-width: 2000px) {
    width: 95%;
  }
}
.LeftContainerHeader {
  margin: 0px 0px 15px 0px;
  color: #005454;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: $tablet) {
    display: none;
  }
}
.LeftContainerSubText {
  margin: 0px 0px 0px 0px;
  color: #005454;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: $tablet) {
    display: none;
  }
}

.mobileHeader {
  display: none;
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10px 10px 10px;
    text-align: center;
  }
  .LeftContainerHeadermobileHeader {
    margin: 0px 0px 8px 0px;
    color: #005454;
    font-size: 20px;
    font-weight: 500;
  }
  .LeftContainerSubTextmobileHeader {
    margin: 0px 0px 0px 0px;
    color: #005454;
    font-size: 14px;
    font-weight: 500;
  }
}
.mobileDisplayOTP {
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopDisplayOTP {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.mobileDisplayOTP {
  display: none;
  width: 100%;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopDisplayOTP {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}
