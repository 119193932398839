@import "../../Components//assets/sass/main";

.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;

  width: 90%;
  background-color: white;
  padding: 3% 0;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}
.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}
.mainWrapper {
  width: 95%;
  margin: auto;
}

.wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 10px;

  // height: 100vh;
  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 0 5px;
    // height: unset;
  }
  @media (min-width: 1400px) {
    //  height: 80vh;
  }

  .leftContainer {
    width: 100%;

    .leftSubContainer {
      width: 100%;

      .headerTitle {
        display: flex;
        width: 97%;
        height: 50px;

        justify-content: space-between;
        align-items: center;
        // border: 1px solid rgb(112, 112, 112);
        background-color: #f2f2f6;

        border-radius: 10px;
        padding: 0 20px;
        margin-bottom: 20px;
        margin-top: 10px;
        @media (max-width: $tablet) {
          // width: 80vw;
          width: 90%;
        }

        .nomineeText {
          color: var(--Grey-15, #262626);
          font-family: "Be Vietnam Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .fieldsSection {
        width: 100%;

        .fields {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 10px;
          flex-wrap: wrap;
          gap: 10px;
          @media (max-width: $tablet) {
            flex-direction: column;
          }

          .textfields {
            width: 100%;

            @media (max-width: $tablet) {
              width: 100%;
            }
          }

          .datepicker {
            width: 100%;

            @media (max-width: $tablet) {
              width: 90vw;
              margin-right: 5px;
              margin-top: 10px;
            }
            @media (min-width: $tablet) and (max-width: 1200px) {
              width: 200px;
            }
            @media (min-width: 2000px) {
              width: 500px;
              margin: 30px 0;
            }
          }
        }

        .addressfields {
          width: 100%;
          // margin-top: 15px;
          @media (max-width: $tablet) {
            width: 90vw;
          }
        }
        .checkBox {
          display: flex;
          align-items: center;
          margin-left: -10px;
          margin-bottom: 20px;
          @media (max-width: $tablet) {
          }
        }
      }
    }

    .radioContainer {
      // position: absolute;
      // top: 22%;
      // right: 10%;
      // border: 1px solid rgb(163, 163, 163);
      border-radius: 10px;
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding: 10px 0px;
      margin-bottom: 10px;
      margin-top: 20px;

      @media (max-width: $tablet) {
        position: relative;
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        right: 0%;
        top: 0%;
        margin-left: 18px;
      }
      @media (min-width: 1400px) {
        top: 15%;
      }
      .selectproof {
        color: var(--Grey-15, #262626);
        font-family: "Be Vietnam Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .rightContainer {
    width: 100%;
    margin: 20px 0px;
    // padding-top: 10px;
    // padding-bottom: 20px;
    // margin: auto;
    @media (max-width: $tablet) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .rightContainerContinue {
    width: 100%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    @media (max-width: $tablet) {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .gaurdianRelation {
    width: 350px;
    margin-top: 15px;
  }
}

.ButtonSection {
  // position: absolute;
  // top: 30%;
  // right: 7%;
  // width: 255px;
  display: flex;
  align-items: center;

  @media (max-width: 468px) {
    position: relative;
    width: 90vw;
    right: 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .addNomineeButton {
    width: 200px;
    margin: 5px 20px 5px 0px;
    @media (max-width: 468px) {
      width: 90vw;
    }
  }

  .Buttons {
    display: flex;
    @media (max-width: $tablet) {
      flex-direction: column;
      margin: auto;
    }
  }

  .startOverSection {
    display: flex;
    margin-top: 0px;

    .iconImage {
      width: 20px;
    }
    .paratext {
      font-size: 14px;
      color: $SubText;
      margin-left: 5px;
      .startoverbutton {
        color: #006779;
        margin-left: 5px;
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
.addNomineeButtonContinue {
  width: 180px;
  padding: 10px 40px;
  @media (max-width: $tablet) {
    padding: 10px 20px;
    width: 140px;
  }
}
.backButton {
  width: 180px;
  padding: 10px 40px;
  color: #262626;
  font-style: normal;
  font-weight: 500;
  @media (max-width: $tablet) {
    padding: 10px 20px;
    width: 140px;
  }
}
.textfieldsOthers {
  width: 350px;
  margin-top: 15px;
}

.Header {
  color: #262626;
  font-family: "Be Vietnam Pro";
  font-size: 25px;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 0px 0;
  width: 100%;
  text-align: left;

  @media (max-width: $tablet) {
    font-size: 20px;
    margin: 0 0 10px 0;
    // text-align: left;
  }
}

.NomineeAddressSection {
  display: flex;
  justify-content: space-between;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.Addressdiv {
  width: 74%;
}

.pincodeField {
  width: 280px;

  @media (max-width: $tablet) {
    width: 90vw;
    margin-top: 10px;
  }
  @media (min-width: $tablet) and (max-width: 1200px) {
    width: 220px;
  }
  @media (min-width: 2000px) {
    width: 500px;
    margin: 0px 0;
  }
}
.PinCodediv {
  @media (max-width: $tablet) {
    margin-top: 10px;
  }
}

.nomineeContactDetails {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 15px 0px 10px 0px;
  // line-height: 150%;
}
.proofTextFeild {
  width: 280px;
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 5px;
}
.checkboxlabel {
  color: #000;
  font-family: "Be Vietnam Pro";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
