.App {
  text-align: center;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
  padding-right: 120px;
}

.completed-step {
  color: green;
  font-weight: bold;
}

.css-otbf8b-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: white !important;
}

.MuiFormControlLabel-label {
  margin-left: 10px !important;
}

/* .MuiOutlinedInput-notchedOutline {
  border: none !important;
} */
.MuiInputLabel-root {
  background-color: white;
}

.MuiSelect-root {
  border: none !important
}

.cropper-crop {
  border-radius: 20px;
}

.MuiPickersLayout-root .MuiPickersLayout-toolbar {
  display: none;
}