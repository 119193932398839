@import "../../Components//assets/sass/main";

#previewSection {
  width: 45%;
  float: left;
  height: 400px;
  margin: 0px 15px;
  border: 1px solid grey;

  border-radius: 10px;
  object-fit: contain;
  @media (max-width: $tablet) {
    display: none;
  }
}

.cropperSection {
  height: 400px;
  width: 80%;
  @media (max-width: $tablet) {
    height: 400px;
    width: auto;
    margin-left: -4px;
  }
}
