@import "../../Components/assets/sass/main";

.wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10px 50px;
  @media (max-width: $tablet) {
    height: 60vh;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }
}

.errorImage {
  width: 25%;
  @media (max-width: $tablet) {
    width: 60%;
  }
}

.pagenotfound {
  text-align: center;
  //   margin-top: 100px;
}

.titleText {
  font-size: 2.5rem;
  color: #ff5733;
  @media (max-width: $tablet) {
    font-size: 1.5rem;
  }
}

.paratext {
  font-size: 1.2rem;
  @media (max-width: $tablet) {
    font-size: 1rem;
  }
}
