@import "../../Components/assets/sass/main";
.wrapper {
  height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  border-radius: 10px;
  // box-shadow: 0px 0px 4px 0px grey;
  width: 75%;
  background-color: white;
  margin: 2% auto;
  @media (max-width: $tablet) {
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0px;
    height: max-content;
    width: 95%;
    box-shadow: none;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }

  .LeftContainer {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px 0px 0px 10px;
    overflow: hidden;
    position: relative;
    background: #4853a2;
    @media (max-width: $tablet) {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      margin-bottom: 20px;
    }

    .LeftContainerHeader {
      margin: 0px 0px 15px 0px;
      color: white;
      width: 80%;
      font-size: 20px;
      text-align: center;
      font-weight: 500;

      line-height: 25px;
      top: 20px;
      @media (max-width: $tablet) {
        // display: none;
      }
    }
    .LeftContainerSubText {
      margin: 0px 0px 0px 0px;

      top: 70px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: $tablet) {
        // display: none;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      // margin-top: 100px;
      //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
      @media (max-width: $tablet) {
        height: 80%;
        width: 70%;
        // display: none;
      }
    }
  }

  .RightContainer {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 0px 3%;
    @media (max-width: $tablet) {
      width: 95%;
      padding: 0px 0%;
    }
    .headerSection {
      display: flex;
      // width: 100%;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      @media (max-width: $tablet) {
        padding: 0px 10px;
      }
      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 0px 0;

        @media (max-width: $tablet) {
          font-size: 25px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .subText {
        color: var(--Grey-30, #4c4c4d);
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 5px 0px;
        @media (max-width: $tablet) {
          width: 100%;
          line-height: 20px;
          font-size: 15px;
          // text-align: left;
        }
      }
    }
    .textfieldLabel {
      color: var(--Grey-15, #262626);
      font-family: "Be Vietnam Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 30px;
    }
    .textfield {
      width: 100%;

      @media (max-width: $tablet) {
        width: 90%;
        margin: 0;
      }
    }
    .smallText {
      color: var(--Grey-35, #59595a);
      font-family: "Be Vietnam Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      margin: 10px 0 0px 0px;
      @media (max-width: $tablet) {
        padding: 0px 10px;
      }
    }
    .buttonSection {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media (max-width: $tablet) {
        display: flex;
        justify-content: center;
      }
    }
    .button {
      width: 100%;
      margin-top: 30px;
      //  box-shadow: 0px 20px 60px rgba(114, 143, 129, 0.39);
      @media (max-width: $tablet) {
        // box-shadow: 0px 2px 10px rgba(114, 143, 129, 0.39);
        margin-top: 20px;
        width: 100%;
      }
    }
    .mobileImage {
      display: none;
      @media (max-width: $tablet) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
      }
      .imageMobileView {
        width: 100%;

        //filter: drop-shadow(12px 12px 7px rgba(0,0,0,0.3));
        @media (max-width: $tablet) {
          // margin: 20px 0;
          margin-left: -40%;
          height: 100%;
        }
      }
    }
  }
}

.LoginText {
  color: var(--Grey-15, #262626);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resendSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: center;
}
.clickhere {
  margin: 0;
  margin-top: 2px;
  padding-right: 5px;
  padding-left: 5px;
  color: $SubText;
  font-size: 14px;
}
.change {
  font-size: 15px;
  color: #006779;
  cursor: pointer;
}
.blockedtext {
  width: 80%;
  text-align: center;
  margin: auto;
  @media (max-width: $tablet) {
    width: 100%;
  }
}

.mobileHeader {
  display: none;
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 10px 10px 10px;
    text-align: center;
  }
  .LeftContainerHeadermobileHeader {
    margin: 0px 0px 8px 0px;
    color: #005454;
    font-size: 20px;
    font-weight: 500;
  }
  .LeftContainerSubTextmobileHeader {
    margin: 0px 0px 0px 0px;
    color: #005454;
    font-size: 14px;
    font-weight: 500;
  }
}
.video {
  width: 60%;

  object-fit: cover;
}
.loaderSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4aa1f8;
  @media (max-width: $tablet) {
    height: 100vh;
  }
}

.loader {
  position: relative;
  width: 75px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0),
    linear-gradient(#ddd 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 3s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #007585;
  border-radius: 50%;
  animation: ballStepUp 3s linear infinite;
}

@keyframes pillerPushUp {
  0%,
  40%,
  100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  }
  50%,
  90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px;
  }
}

@keyframes ballStepUp {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px);
  }
  17% {
    transform: translate(23px, -24px);
  }
  20% {
    transform: translate(30px, -20px);
  }
  27% {
    transform: translate(38px, -34px);
  }
  30% {
    transform: translate(45px, -30px);
  }
  37% {
    transform: translate(53px, -44px);
  }
  40% {
    transform: translate(60px, -40px);
  }
  50% {
    transform: translate(60px, 0);
  }
  57% {
    transform: translate(53px, -14px);
  }
  60% {
    transform: translate(45px, -10px);
  }
  67% {
    transform: translate(37px, -24px);
  }
  70% {
    transform: translate(30px, -20px);
  }
  77% {
    transform: translate(22px, -34px);
  }
  80% {
    transform: translate(15px, -30px);
  }
  87% {
    transform: translate(7px, -44px);
  }
  90% {
    transform: translate(0, -40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
