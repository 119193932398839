@media screen and (max-width:600px){
    .mobileStyling{
   display:flex;
   flex-direction: column;
   width: 200px;

    }
    .yourprofile{
        margin-top: '80px';
    }
 
}