@import "../../Components/assets/sass/main";

.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 40px;
  border-radius: 8px;
  padding-bottom: 30px;
  width: 90%;
  height: max-content;
  background-color: white;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}
.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 95%;
  margin: auto;
  padding: 3% 0px;
  height: 100%;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
    height: max-content;
    gap: 30px;
    width: 95%;
  }

  .leftContainer {
    width: 70%;
    height: 100%;
    @media (max-width: $tablet) {
      width: 100%;
      // padding: 0px 10px;
    }
    .headerSection {
      position: relative;
      @media (max-width: $tablet) {
      }

      .signup {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;
        width: 100%;

        @media (max-width: $tablet) {
          font-size: 20px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .subText {
        line-height: 30px;
        margin: 0;
        color: $SubText;
        font-size: 16px;
        @media (max-width: $tablet) {
          width: 90vw;
          line-height: 20px;
          font-size: 14px;
        }
      }
      .previewButton {
      }
    }
  }

  .rightContainer {
    width: 30%;
    height: 100%;

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    @media (max-width: $tablet) {
      width: 100%;
      flex-direction: column-reverse;
    }

    .imagediv {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        width: 80%;
        height: 100%;

        @media (max-width: $tablet) {
          width: 80%;
        }
      }
    }
  }
}

.pdfMobilePreview {
  display: none;
  width: 100%;
  height: 85%;
  @media (max-width: $tablet) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 85%;
  }
}
.pdfDesktopPreview {
  display: block;
  height: 85%;
  @media (max-width: $tablet) {
    display: none;
  }
}

.startOverSection {
  display: flex;
  margin-top: 10px;

  .iconImage {
    width: 20px;
  }
  .paratext {
    font-size: 14px;
    color: $SubText;
    margin-left: 5px;
    .startoverbutton {
      color: $Success;
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

.referralSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: $tablet) {
    justify-content: flex-start;
  }
  .referraltext {
    color: var(--Grey-15, #262626);
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin: 0px 10px 0px 0px;
  }
}
.copyreferral {
  display: flex;
  align-items: center;
  @media (max-width: $tablet) {
    width: 100%;
  }
}
.shareWrapper {
  // background-color: red;
  padding: 0px 20px;
  border-radius: 16px;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  @media (max-width: $tablet) {
    width: 100%;
    padding: 0px 0px;
  }
}
.shareimageicon {
  width: 60%;
  filter: hue-rotate(30deg);
}
.copyreferralTextFeild {
  width: 400px;
  // border: 1px solid #98a2b3;
  border-radius: 8px;
  padding: 5px 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f6;
  align-items: center;
  @media (max-width: $tablet) {
    width: 100%;
    margin-left: 0px;
  }
}
.copyreferralurlText {
  margin-left: 10px;
  color: black;

  font-size: 12px;
  font-weight: 600;
}
.shareMainWrapper {
  display: flex;
  width: 95%;
  margin: 10px auto;
  justify-content: space-between;
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 95%;
  }
}
.copyreferralMainSection {
  display: flex;
  align-items: center;
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
.header {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  margin: 10px 0px;
}

.containerTextFeilds {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $tablet) {
    width: 100%;
  }
}
.textfieldLabel {
  color: var(--Grey-15, #262626);
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 3px;
}
.viewDocButton {
  @media (max-width: $tablet) {
    width: 100%;
  }
}
.imageSection {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: $tablet) {
    width: 100%;
    justify-content: flex-start;
  }
}
.previewdownlaodesign {
  position: absolute;
  top: 50%;
}
