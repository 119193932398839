@import "../../Components/assets/sass/main";
.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 40px;
  border-radius: 8px;
  padding-bottom: 30px;
  width: 90%;
  height: max-content;
  background-color: white;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}
.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}
.wrapper {
  // height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 95%;
  margin: auto;
  padding: 3% 0px 0px 0px;
  height: 100%;

  @media (max-width: $tablet) {
    flex-direction: column-reverse;
    height: max-content;
    gap: 30px;
    width: 95%;
  }
  .container {
    // height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    @media (max-width: $tablet) {
      width: 100%;
    }

    .header {
      color: #262626;
      font-family: "Be Vietnam Pro";
      font-size: 25px;
      font-weight: 600;
      line-height: normal;
      margin: 0 0 20px 0;
      width: 100%;

      @media (max-width: $tablet) {
        font-size: 20px;
        margin: 0 0 10px 0;
        // text-align: left;
      }
    }
    .image {
      // width: 35vh;
      // margin-right: 50px;
      // margin-bottom: 30px;
      // margin-top: 20px;
      @media (max-width: $tablet) {
        margin-right: 0px;
        margin-bottom: 20px;
        width: 90%;
      }
    }
    .subText {
      width: 50%;
      text-align: center;
      line-height: 30px;
      margin: 0;
      color: $SubText;
      font-size: 20px;
      @media (max-width: $tablet) {
        text-align: left;
        width: 90%;
        line-height: 20px;
        font-size: 16px;
      }
    }

    .startOverSection {
      display: flex;
      align-items: center;
      // margin-top: 10px;
      @media (max-width: $tablet) {
      }
      .iconImage {
        width: 20px;
      }
      .paratext {
        font-size: 14px;
        color: $SubText;
        margin-left: 5px;
        @media (max-width: $tablet) {
          font-size: 13px;
        }
        .startoverbutton {
          color: #006779;
          font-weight: 600;
          margin-left: 0px;
          cursor: pointer;
        }
      }
    }
  }
  .approveButton {
    width: 300px;
    margin: 20px 0 0px 0;
  }
}
.shareimageicon {
  width: 80%;
  filter: hue-rotate(30deg);
}

.referralSection {
  display: flex;
  align-items: center;

  .referraltext {
    margin: -5px 0 0 0;
    font-size: 18px;
    font-weight: 500;
    color: "#122460";
    @media (max-width: $tablet) {
      font-size: 16px;
    }
  }
  .imageSection {
    width: 250px;
    display: flex;
    justify-content: space-evenly;
  }
}
.copyreferral {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.copyreferralTextFeild {
  width: 400px;
  // border: 1px solid #98a2b3;
  border-radius: 10px;
  padding: 5px 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  background-color: #c8dff7;
  align-items: center;
  @media (max-width: $tablet) {
    width: 90%;
    margin-left: 0px;
  }
}
.copyreferralurlText {
  margin-left: 10px;
  color: black;
  margin-top: -2px;
  font-size: 12px;
  font-weight: 600;
}
.copyclientCodeTextFeild {
  width: 200px;
  border: 1px solid #98a2b3;
  height: 35px;
  display: flex;

  justify-content: space-between;
  align-items: center;
  @media (max-width: $tablet) {
    width: 60%;
  }
}
.copyclientCodeText {
  margin-left: 10px;
  color: #98a2b3;
  margin-top: -2px;
  font-size: 14px;
}
.shareWrapper {
  // background-color: red;
  padding: 10px 20px;
  border-radius: 16px;
  box-shadow: 0px 0px 3px -1px #323247;
}

.shareSection {
  display: flex;

  justify-content: space-between;
  width: 100%;

  @media (max-width: $tablet) {
    flex-direction: column;
    width: 90vw;
  }
}
.appstoreSection {
  margin-left: 60px;
  @media (max-width: $tablet) {
    margin-left: 0px;
  }
}
.appstorelinks {
  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
  }
}
.sharetext {
  margin: 0px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #122460;
  text-align: center;

  @media (max-width: $tablet) {
    font-size: 20px;
    margin-top: 30px;
  }
}
.Googlestoreimage {
  // width: 22%;
  margin-top: 10px;
  margin-right: 10px;
  @media (max-width: $tablet) {
    width: 105px;
  }
}
.Appstoreimage {
  // width: 20%;
  margin-top: 10px;
  margin-right: 10px;
  @media (max-width: $tablet) {
    width: 95px;
  }
}
.Microstoreimage {
  // width: 19%;
  margin-top: 10px;
  margin-right: 10px;
  @media (max-width: $tablet) {
    width: 90px;
  }
}

.bottomText {
  font-weight: 600;
  font-family: "Be Vietnam Pro";
}
.bottomSubText {
  font-weight: 400;
  font-family: "Be Vietnam Pro";
}
.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $tablet) {
    flex-direction: column;
  }
  .contentWrapperLeft {
    width: 40%;
    @media (max-width: $tablet) {
      width: 100%;
    }
  }
  .bottomTextSection {
    // margin-top: 30px;
    width: 50%;

    @media (max-width: $tablet) {
      width: 100%;
      margin-top: 20px;
    }
  }
}
