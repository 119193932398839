@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  /* font-family: Poppins; */

  background-color: #ededed5c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* max-width: 1700px; */
  width: 100%;
  margin: auto;


}



/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.MuiFormControl-root {
  width: 100% !important
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  margin-top: 20px;
  display: flex;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  margin-top: 20px;
  display: flex;
}

.react-confirm-alert-overlay {
  background: rgba(85, 84, 84, 0.562) !important;
  animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;

}

@media (max-width:468px) {
  .react-confirm-alert-body {
    width: 90%;
    padding: 10px;
    margin: auto;
  }
}

.react-confirm-alert-button-group>button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}