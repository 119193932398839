@import "../../Components/assets/sass/main";

.mainWrapperTop {
  border: 1px solid #d6d7dd;
  margin: 0px auto 10px;
  border-radius: 8px;
  padding-bottom: 30px;
  width: 90%;
  height: max-content;
  background-color: white;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 20px auto;
    border: none;
    width: 95%;
    border-radius: 6px;
    padding: 8% 0;
    position: relative;
  }
  @media (min-width: 2000px) {
    width: 50%;
  }
}

.mobileProgress {
  position: absolute;
  right: 3%;
  top: 10px;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
}
.desktopProgress {
  display: block;
  @media (max-width: $tablet) {
    display: none;
  }
}

.wrapper {
  // min-height: 80vh;
  display: flex;
  padding: 3% 0;
  width: 90%;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  @media (max-width: $tablet) {
    padding: 10px 10px 0 10px;
    min-height: max-content;
  }
  @media (min-width: 2000px) {
    height: 60vh;
  }
  .titleSection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    @media (max-width: $tablet) {
      flex-direction: column;
    }
    .leftcontainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      width: 50%;
      @media (max-width: $tablet) {
        width: 100%;
      }
      .title {
        color: #262626;
        font-family: "Be Vietnam Pro";
        font-size: 25px;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;
        width: 100%;

        @media (max-width: $tablet) {
          font-size: 20px;
          margin: 0 0 10px 0;
          // text-align: left;
        }
      }
      .leftcontainerText {
        color: #262626;
        // font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Be Vietnam Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.049px; /* 115.246% */
        margin: 8px 0;
        letter-spacing: 0.576px;
        @media (max-width: $tablet) {
          margin: 3px 0;
          font-size: 14px;
          width: 100%;
        }
      }
      .rightmobileViewcontainer {
        display: none;
        @media (max-width: $tablet) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .image {
          width: 80%;
          @media (max-width: $tablet) {
            width: 80%;
            margin: 20px 0px;
          }
        }
      }
    }
    .rightcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 0;
      width: 40%;
      @media (max-width: $tablet) {
        display: none;
      }

      .image {
        width: 80%;
        @media (min-width: 2000px) {
          width: 100%;
        }
      }
    }
  }
  .buttonStyles {
    // margin-top: 10px;
    display: flex;
    margin-top: 5%;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: $tablet) {
      // flex-direction: column;
      margin-top: 15%;
    }
    .backButton {
      width: 180px;
      padding: 10px 40px;
      color: #262626;
      font-style: normal;
      font-weight: 500;
      @media (max-width: $tablet) {
        padding: 10px 20px;
        width: 140px;
      }
    }
    .esignButton {
      width: 180px;
      @media (max-width: $tablet) {
        width: 140px;
        padding: 10px 20px;
      }
    }

    .continue {
      width: 180px;
      @media (max-width: $tablet) {
        width: 140px;
        padding: 10px 20px;
      }
    }
    .buttonStyles {
      width: 300px;
    }
    .startOverSection {
      display: flex;
      // margin-top: 10px;

      .iconImage {
        width: 20px;
      }
      .paratext {
        font-size: 14px;
        color: $SubText;
        margin-left: 5px;
        .startoverbutton {
          color: #006779;
          font-weight: 600;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

.pdfMobilePreview {
  display: none;
  @media (max-width: $tablet) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 70vh;
    position: relative;
  }
}
.pdfDesktopPreview {
  display: block;
  height: 80%;

  @media (max-width: $tablet) {
    display: none;
  }
}
.sectionButton {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .esignPreview {
    @media (max-width: $tablet) {
      margin-top: 20px;
    }
  }
}
.startover {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-family: "Be Vietnam Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  .startoverbutton {
    color: #4754a0;
    font-family: "Be Vietnam Pro";
    font-size: 14px;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.previewdownlaodesign{
  position: absolute;
  top: 50%;
}