@import "../../Components/assets/sass/main";

.mainWrapperTop {
  box-shadow: 0px 0px 4px 0px grey;
  margin: 10px 30px 30px 30px;
  border-radius: 10px;
  padding-bottom: 30px;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: $tablet) {
    box-shadow: none;
    margin: 0px 0px 50px 0px;
  }
  @media (min-width: 2000px) {
    width: 60%;
  }
}
